import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Flex, Text } from "rebass/styled-components";
import IdeasFilters from "../IdeaFilters/IdeasFilters";
import { IdeaTypeFilter } from "../IdeaFilters/IdeaTypeFilters";
import Divider from "components/common/Divider";
import { Add } from "@styled-icons/fluentui-system-filled/Add";
import { theme } from "theme";
import IdeaEntry from "./IdeaEntry";
import { IdeaGridContainer } from "../styles";
import { isAfter, parseISO, sub } from "date-fns";
import { saveDrafts } from "state/actions/DraftActions";
import { useContentoApi } from "utils/useContentoApi";
import { useSelector, useDispatch } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import { useComposerActions } from "contextApi/composerContext";
import { type IUserIdea } from "state/reducers/IdeasReducer";
import { setWhatsappAssets } from "state/actions/IdeasActions";
import { IRootState } from "state/reducers";

const AddIdeaButton = () => {
  const openComposer = useComposerActions(actions => actions.openComposer);

  const handleAddIdea = useCallback(() => {
    openComposer(null, {
      isDraft: true
    });
  }, [openComposer]);

  return (
    <Button
      display={"flex"}
      padding={"20px 10px"}
      color={theme.colors.black}
      onClick={() => handleAddIdea()}
      sx={{
        border: "1px dashed #1C1E1F54",
        borderRadius: "8px",
        background: "#F7F9FA",
        gap: "12px",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "12px",
        cursor: "pointer"
      }}
    >
      <Add size={20} />
      <span>Click to add a new idea</span>
    </Button>
  );
};

const IdeaGrid = ({ ideas }: { ideas: IUserIdea[] }) => {
  if (ideas.length < 1) {
    return (
      <Flex justifyContent={"center"} alignItems={"center"} marginY={"20px"}>
        <Text fontWeight={600}>No Ideas Found</Text>
      </Flex>
    );
  }

  return (
    <IdeaGridContainer>
      {ideas && ideas.map(idea => <IdeaEntry key={idea.id} idea={idea} />)}
    </IdeaGridContainer>
  );
};

interface UserIdeasProps {
  origin?: "whatsapp";
}

const UserIdeas: React.FC<UserIdeasProps> = ({ origin }) => {
  const dispatch = useDispatch();
  const addToast = useToaster();
  const [ideaTypeFilter, setIdeaTypeFilter] = useState(IdeaTypeFilter.All);
  const [periodFilter, setPeriodFilter] = useState<
    7 | 30 | 90 | 180 | 365 | undefined
  >(undefined);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const accountId = useSelector((state: IRootState) => state.account.data.id);
  const userId = useSelector((state: IRootState) => state.auth.currentUser.id);
  const drafts = useSelector((state: IRootState) => state.draft.drafts);
  const whatsappAssets = useSelector(
    (state: IRootState) => state.ideas.whatsappAssets
  );
  const [isFetchingDrafts, setIsFetchingDrafts] = useState(false);
  const [isFetchingWhatsappAssets, setIsFetchingWhatsappAssets] =
    useState(false);

  const [fetchDrafts, cancelFetchDrafts] = useContentoApi(
    `accounts/${accountId}/draft-posts`
  );

  const [fetchWhatsappAssets, cancelFetchWhatsappAssets] = useContentoApi(
    `users/${userId}/assets/${accountId}`
  );

  const refreshDrafts = useCallback(() => {
    setIsFetchingDrafts(true);
    (fetchDrafts() as any)
      .then((response: any) => {
        const drafts = response.map((draft: any) => {
          return {
            ...draft,
            isDraft: true
          };
        });

        console.log("Drafts:: ", drafts);

        setIsFetchingDrafts(false);
        dispatch(saveDrafts(drafts));
      })
      .catch((e: Error) => {
        setIsFetchingDrafts(false);
        addToast("Couldn't get draft posts at the moment", "error");
      });

    return () => {
      cancelFetchDrafts();
    };
  }, [origin, fetchDrafts, addToast, dispatch, accountId]);

  useEffect(() => {
    refreshDrafts();
  }, [refreshDrafts]);

  const refreshWhatsappAssets = useCallback(() => {
    setIsFetchingWhatsappAssets(true);
    (fetchWhatsappAssets() as any)
      .then((response: any) => {
        const whatsappAssets = response.assets.map((asset: any) => {
          return {
            ...asset,
            isDraft: true,
            isWhatsappAsset: true,
            channels: asset.channels || [],
            caption: {
              all: asset.asset_content
            }
          };
        });

        console.log("Whatsapp Assets:: ", whatsappAssets);
        dispatch(setWhatsappAssets(whatsappAssets));
      })
      .catch((e: Error) => {
        addToast("Couldn't fetch Whatsapp Assets at the moment", "error");
      })
      .finally(() => {
        setIsFetchingWhatsappAssets(false);
      });

    return () => {
      cancelFetchWhatsappAssets();
    };
  }, [
    origin,
    fetchWhatsappAssets,
    cancelFetchWhatsappAssets,
    dispatch,
    addToast,
    userId,
    accountId
  ]);

  useEffect(() => {
    refreshWhatsappAssets();
  }, [refreshWhatsappAssets]);

  const ideas: IUserIdea[] = useMemo(() => {
    return [...(drafts as any), ...whatsappAssets];
  }, [drafts, whatsappAssets]);

  const categories = useMemo(() => {
    // map the ideas category into an array and filter out the undefined
    const categoriesMap = ideas.map(idea => idea.contentTypeId);
    // @ts-ignore
    const categoriesSet: Set<string> = new Set(
      categoriesMap.filter(category => category !== undefined)
    );
    const categoriesArr = Array.from(categoriesSet);
    setSelectedCategories(categoriesArr);

    return categoriesArr;
  }, [ideas, setSelectedCategories]);

  const filteredIdeas = useMemo(() => {
    let filtered = ideas.slice();
    // check if we want to display only whatsapp ideas
    if (origin && origin === "whatsapp") {
      filtered = filtered.filter(idea => idea.isWhatsappAsset === true);
    }

    switch (ideaTypeFilter) {
      case IdeaTypeFilter.Images:
        filtered = filtered.filter(idea => idea.attachment?.type === "photo");
        break;
      case IdeaTypeFilter.Videos:
        filtered = filtered.filter(idea => idea.attachment?.type === "video");
        break;
      case IdeaTypeFilter.Links:
        filtered = filtered.filter(idea => idea.attachment?.type === "article");
        break;
      case IdeaTypeFilter.PDFs:
        filtered = filtered.filter(idea => idea.attachment?.type === "pdf");
        break;
      case IdeaTypeFilter.All:
      default:
        break;
    }

    if (periodFilter) {
      const breakpoint = sub(new Date(), { days: periodFilter });
      filtered = filtered.filter(idea =>
        isAfter(parseISO(idea.createdAt), breakpoint)
      );
    }
    /// TODO:: Uncomment this to reimplement filtering by contentTypeId
    // filtered = filtered.filter(
    //   p => p.contentTypeId && selectedCategories.includes(p.contentTypeId)
    // );

    return filtered;
  }, [origin, ideaTypeFilter, ideas, periodFilter, selectedCategories]);

  console.log("Filtered Ideas:: ", filteredIdeas);

  return (
    <Flex flexDirection={"column"} padding={"16px 0"}>
      <IdeasFilters
        periodFilter={periodFilter}
        onPeriodFilterChange={v => setPeriodFilter(v)}
        ideaTypeFiler={ideaTypeFilter}
        onAttachmentTypeChange={x => setIdeaTypeFilter(x)}
        categories={categories}
        selectedCategories={selectedCategories}
        onCategoryFilterChange={v => setSelectedCategories(v)}
      />
      <Divider my={4} />
      <AddIdeaButton />
      <IdeaGrid ideas={filteredIdeas} />
    </Flex>
  );
};

export default UserIdeas;
