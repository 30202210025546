import { Switch } from "react-router";
import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, Route, useHistory } from "react-router-dom";

import {
  IdeaNav,
  IdeaWrapper,
  IdeaPageTitle,
  IdeaPageHeader,
  IdeaPageContainer,
  IdeaPageDescription
} from "components/ideas/styles";
import Button from "components/common/Button";
import OldIdeaGrid from "components/ideas/OldIdeaGrid";
import { useAppState } from "contextApi/appContext";
import SavedPosts from "components/ideas/savedPosts";
import { useContentoApi } from "utils/useContentoApi";
import TopicalCalendars from "components/ideas/topicalCalendars";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

const IdeasPage = React.memo(({ match }: { match: any }) => {
  const account = useSelector((state: any) => state.account.data);
  const showWillowExtension = useAppState(state => state.showWillowExtension);
  const dispatch = useDispatch();
  const [postConcepts, setPostConcepts] = useState<any[]>([]);
  const addToast = useToaster();
  const history = useHistory();
  const matchedPath = matchPath(history.location.pathname, {
    path: `/accounts/${account.id}/ideas/:postConceptSlug`
  });
  const postConceptSlug =
    matchedPath && (matchedPath.params as any).postConceptSlug;

  const [fetchPostConcepts, cancelFetchPostConcepts] =
    useContentoApi("post-concepts");

  useEffect(() => {
    dispatch(trackAnalyticsEvent("Ideas Viewed"));
  }, [dispatch]);

  useEffect(() => {
    (fetchPostConcepts() as any).then(setPostConcepts).catch(error => {
      addToast(error.message, "error");
    });
    return () => {
      cancelFetchPostConcepts();
    };
  }, [cancelFetchPostConcepts, fetchPostConcepts, addToast]);

  if (!postConcepts) {
    return null;
  }

  return (
    <IdeaWrapper>
      {/* {showWillowExtension && <SavedPosts />} */}

      <TopicalCalendars />

      <IdeaPageHeader>
        <IdeaPageTitle mb={12}>Post Ideas</IdeaPageTitle>
        <IdeaPageDescription>
          With this library of content ideas for each of the major social
          platforms, you’ll be ahead of the curve. And you’ll never find
          yourself staring at a blank content calendar again.
        </IdeaPageDescription>
      </IdeaPageHeader>

      <IdeaNav>
        <Button
          size="lg"
          variant={postConceptSlug ? "secondary" : "black"}
          onClick={() => history.push(`/accounts/${account.id}/ideas`)}
          marginRight={8}
          isRounded
        >
          All
        </Button>
        {postConcepts.map(postConcept => (
          <Button
            key={postConcept.id}
            size="lg"
            variant={
              postConcept.slug === postConceptSlug ? "black" : "secondary"
            }
            onClick={() =>
              history.push(`/accounts/${account.id}/ideas/${postConcept.slug}`)
            }
            marginRight={8}
            isRounded
          >
            {postConcept.title}
          </Button>
        ))}
      </IdeaNav>
      <Switch>
        <Route path={match.url + "/:postConceptSlug"} component={OldIdeaGrid} />
        <Route exact path={match.url} component={OldIdeaGrid} />
      </Switch>
    </IdeaWrapper>
  );
});

export default IdeasPage;
