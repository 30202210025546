import React from "react";

import { Calendar } from "@styled-icons/boxicons-solid/Calendar";
import {Bulb} from "@styled-icons/ionicons-outline/Bulb";
import { Home, DataPie } from "@styled-icons/fluentui-system-filled";
import {Compass} from "@styled-icons/remix-fill";

import { useAppState } from "contextApi/appContext";
import { NavItemList, NavItem } from "./styles";
import NavItemIcon from "./NavItemIcon";

const MainNavigation = React.memo(({ accountId, wasClicked }: {accountId: string, wasClicked: () => void}) => {
  const client = useAppState(state => state.clientSettings.client);

  return (
    <NavItemList onClick={wasClicked}>
      <NavItem to={`/accounts/${accountId}/dashboard`}>
        <NavItemIcon icon={<Home size={20} />} />
        <label>Home</label>
      </NavItem>
      <NavItem to={`/accounts/${accountId}/ideas`}>
        <NavItemIcon icon={<Bulb size={20} strokeWidth={40} />} />
        <label>Ideas</label>
      </NavItem>
      <NavItem to={`/accounts/${accountId}/schedule`}>
        <NavItemIcon icon={<Calendar size={20} />} />
        <label>Calendar</label>
      </NavItem>
      {client !== "telenet" && (
      <NavItem to={`/accounts/${accountId}/content`}>
        <NavItemIcon icon={<Compass size={20} />} />
        <label>News</label>
      </NavItem>
      )} 

      <NavItem to={`/accounts/${accountId}/analytics`}>
        <NavItemIcon icon={<DataPie size={20} />} />
        <label>Analytics</label>
      </NavItem>
    </NavItemList>
  );
});

export default MainNavigation;
