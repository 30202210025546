import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";

import OldIdeaEntry from "./OldIdeaEntry";
import IdeaEntry from "./UserIdeas/IdeaEntry";
import { IdeaGridContainer } from "./styles";
import { useContentoApi } from "utils/useContentoApi";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { useAppState } from "contextApi/appContext";

const OldIdeaGrid: React.FC<{ limit?: number }> = ({ limit = -1 }) => {
  const dispatch = useDispatch();
  const [postIdeas, setPostIdeas] = useState<any[]>([]);
  const addToast = useToaster();
  const { postConceptSlug } = useParams<any>();
  const showIdeasPageRevamp = useAppState(state => state.showIdeasPageRevamp);

  const [fetchPostIdeas, cancelFetchPostIdeas] = useContentoApi(
    `post-ideas${postConceptSlug ? "?postConceptSlug=" + postConceptSlug : ""}`
  );

  useEffect(() => {
    if (!!postConceptSlug) {
      dispatch(
        trackAnalyticsEvent("Ideas Filtered", {
          filterApplied: postConceptSlug
        })
      );
    }
  }, [dispatch, postConceptSlug]);

  useEffect(() => {
    (fetchPostIdeas() as any)
      .then(res => {
        limit !== -1 ? setPostIdeas(res.slice(0, limit)) : setPostIdeas(res);
      })
      .catch(error => {
        addToast(error.message, "error");
      });
    return () => {
      cancelFetchPostIdeas();
    };
  }, [limit, cancelFetchPostIdeas, fetchPostIdeas, addToast]);

  console.log("Post Ideas:: ", postIdeas);

  return (
    <IdeaGridContainer>
      {postIdeas &&
        postIdeas.map(postIdea =>
          showIdeasPageRevamp ? (
            <IdeaEntry key={postIdea.id} idea={postIdea} />
          ) : (
            <OldIdeaEntry key={postIdea.id} idea={postIdea} />
          )
        )}
    </IdeaGridContainer>
  );
};

export default OldIdeaGrid;
