import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Keyword from "components/content/keyword";
import IdeasPage from "../ideas/PostingdeasPage";
import ContentForYou from "components/content/ContentForYou";
import ContentSearch from "components/content/ContentSearch";
import ContentFollowed from "components/content/ContentFollowed";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import ContentExplore from "components/content/explore/ContentExplore";
import ContentTopicDetail from "components/content/ContentTopicDetail";
import ContentPreferences from "components/content/ContentPreferences";
import ContentInfluencers from "components/content/ContentInfluencers";
import ContentSavedForLater from "components/content/ContentSavedForLater";
import ContentKeywordDetail from "components/content/ContentKeywordDetail";
import ContentInfluencerDetail from "components/content/ContentInfluencerDetail";
import UserIdeas from "components/ideas/UserIdeas/UserIdeas";

const ContentRouter: React.FC<{}> = React.memo(() => {
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const pageType =
      location.pathname.match(
        /^.*\/accounts\/[^/]*\/content\/([^/?]*).*$/
      )?.[1] ?? "";

    if (!!pageType) {
      dispatch(
        trackAnalyticsEvent("News Viewed", {
          pageType
        })
      );
    }
  }, [dispatch, location.pathname]);

  return (
    <Switch>
      <Route path={match.url + "/for-you"} component={ContentForYou} />
      <Route path={match.url + "/explore"} component={ContentExplore} />
      <Route
        path={match.url + "/saved-for-later"}
        component={ContentSavedForLater}
      />
      <Route path={match.url + "/preferences"} component={ContentPreferences} />
      <Route path={match.url + "/search"} component={ContentSearch} />
      <Route
        path={match.url + "/topics/:topicId/:language"}
        component={ContentTopicDetail}
      />
      <Route path={match.url + "/topics"} component={ContentFollowed} />
      <Route
        path={match.url + "/domains/:sourceId"}
        render={params => (
          <ContentInfluencerDetail sourceType="domains" {...params} />
        )}
      />

      <Route
        path={match.url + "/influencers/:sourceId"}
        render={params => (
          <ContentInfluencerDetail sourceType="twitter" {...params} />
        )}
      />
      <Route
        path={match.url + "/influencers"}
        render={() => <ContentInfluencers sourceType="twitter" />}
      />
      <Route
        path={match.url + "/rss/:sourceId"}
        render={params => (
          <ContentInfluencerDetail sourceType="rss" {...params} />
        )}
      />
      <Route
        exact
        path={match.url + "/keywords/:sourceId"}
        render={params => <ContentKeywordDetail {...params} />}
      />
      <Route
        path={match.url + "/rss"}
        render={() => <ContentInfluencers sourceType="rss" />}
      />
      <Route exact path={match.url + "/keywords"} render={() => <Keyword />} />
      <Route
        exact
        path={match.url + "/keywords/:keywordId/edit"}
        render={params => <Keyword {...params} />}
      />
      <Route path={match.url + "/ideas"} component={IdeasPage} />
      <Route path={match.url + "/whatsapp"} component={UserIdeas} />
      <Route path={match.url + "/your-ideas"} component={UserIdeas} />
      <Redirect exact from={match.url} to={match.url + "/for-you"} />
    </Switch>
  );
});

export default ContentRouter;
