import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGranularEffect } from "granular-hooks";
import {
  SectionLabel,
  SectionWrapper,
  SectionContent,
  ComposerContent,
  SelectorWrapper
} from "../../styles";
import Captions from "./captions";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Attachment from "./attachment";
import StatusSelector from "./statusSelector";
import ChannelSelector from "./channelSelector";
import CategorySelector from "./categorySelector";
import validateAttachments from "./attachment/validation";
import EmptyChannelState from "components/common/EmptyChannelState";
import { useAppState } from "contextApi/appContext";

function PostComposer() {
  const account = useSelector((state: any) => state.account.data);
  const inProMode = useComposerState(state => state.inProMode);
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);
  const attachments = useComposerState(state => state.attachments);
  const inDraftMode = useComposerState(state => state.inDraftMode);

  const {
    type,
    options,
    photoAttachments,
    videoAttachments,
    articleAttachments
  } = attachments["all"];
  const closeComposer = useComposerActions(actions => actions.closeComposer);
  const getEnabledServices = useComposerActions(
    actions => actions.getEnabledServices
  );
  const setAttachmentOptions = useComposerActions(
    actions => actions.setAttachmentOptions
  );
  const setAttachmentValidation = useComposerActions(
    actions => actions.setAttachmentValidation
  );

  const enabledServices = useMemo(
    // @ts-ignore
    () => getEnabledServices(),
    [getEnabledServices]
  );

  useGranularEffect(
    () => {
      const attachmentValidation = validateAttachments(
        account,
        {
          type,
          options,
          photoAttachments: photoAttachments.attachments,
          videoAttachments: videoAttachments.attachments,
          articleAttachments: articleAttachments.attachment
        },
        setAttachmentOptions,
        enabledServices,
        inDraftMode
      );

      setAttachmentValidation(attachmentValidation);
    },
    [
      setAttachmentValidation,
      account,
      type,
      options,
      enabledServices.length,
      photoAttachments.attachments,
      videoAttachments.attachments,
      articleAttachments.attachment,
      setAttachmentOptions,
      inDraftMode
    ],
    [enabledServices]
  );

  useEffect(() => {
    const videoCount = videoAttachments.attachments.length;
    const hasVideo = type === "video" && videoCount > 0;
    if (enabledServices.includes("instagram") && hasVideo) {
      setAttachmentOptions({ isReel: true });
    }
  }, [videoAttachments.attachments.length]);

  return (
    <ComposerContent $inProMode={inProMode || showWillowAIRevamp}>
      <Captions />
      {!inProMode && !showWillowAIRevamp && <Attachment />}
      {!inProMode && !showWillowAIRevamp && (
        <SelectorWrapper>
          <SectionWrapper>
            <SectionLabel>Channels</SectionLabel>
            <SectionContent $fullWidth={true}>
              {account.channels < 1 ? (
                <EmptyChannelState callback={closeComposer} />
              ) : (
                <ChannelSelector />
              )}
            </SectionContent>
          </SectionWrapper>
          <SectionWrapper $center>
            <SectionLabel>Category</SectionLabel>
            <SectionContent>
              <CategorySelector />
            </SectionContent>
          </SectionWrapper>
          <SectionWrapper $center>
            <SectionLabel>Status</SectionLabel>
            <SectionContent>
              <StatusSelector />
            </SectionContent>
          </SectionWrapper>
        </SelectorWrapper>
      )}
    </ComposerContent>
  );
}

export default PostComposer;
